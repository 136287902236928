export const roles = [{
  title: '档案中心',
  value: 'ACCOUNT',
  disabled: false,
  child: [
    {
      title: '机构管理',
      value: 'ACCOUNT:ORG',
      disabled: false,
      child: [{
        title: '机构列表',
        value: 'ACCOUNT:ORG:ORGS',
        disabled: false,
        child: [{
          title: '查看',
          value: 'ACCOUNT:ORG:ORGS/SHOW',
          disabled: false,
        }, {
          title: '新增',
          value: 'ACCOUNT:ORG:ORGS/ADD',
          disabled: false,
        }, {
          title: '激活状态',
          value: 'ACCOUNT:ORG:ORGS/ACTIVATE',
          disabled: false,
        }, {
          title: '详情',
          value: 'ACCOUNT:ORG:ORGS/DETAIL',
          disabled: false,
          child: [{
            title: '基本信息',
            value: 'ACCOUNT:ORG:ORGS:BASE',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:ORG:ORGS:BASE/SHOW',
              disabled: false,
            },
            {
              title: '编辑',
              value: 'ACCOUNT:ORG:ORGS:BASE/EDIT',
              disabled: false,
            }]
          }, {
            title: '产品信息',
            value: 'ACCOUNT:ORG:ORGS:CONFIG',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:ORG:ORGS:CONFIG/SHOW',
              disabled: false,
            }, 
            {
              title: '新增',
              value: 'ACCOUNT:ORG:ORGS:CONFIG/ADD',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:ORG:ORGS:CONFIG/EDIT',
              disabled: false,
            }, {
              title: '删除',
              value: 'ACCOUNT:ORG:ORGS:CONFIG/DELETE',
              disabled: false,
            }]
          }]
        }]
      }]
    }, {
      title: '商户管理',
      value: 'ACCOUNT:MCH',
      disabled: false,
      child: [{
        title: '商户列表',
        value: 'ACCOUNT:MCH:MCHS',
        disabled: false,
        child: [{
          title: '查看',
          value: 'ACCOUNT:MCH:MCHS/SHOW',
          disabled: false,
        }, {
          title: '新增',
          value: 'ACCOUNT:MCH:MCHS/ADD',
          disabled: false,
        }, {
          title: '详情',
          value: 'ACCOUNT:MCH:MCHS/DETAIL',
          disabled: false,
          child: [{
            title: '基本信息',
            value: 'ACCOUNT:MCH:MCHS:BASE',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:MCH:MCHS:BASE/SHOW',
              disabled: false,
            },  {
              title: '重发邮件',
              value: 'ACCOUNT:MCH:MCHS:BASE/RESENDMAIl',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:MCH:MCHS:BASE/EDIT',
              disabled: false,
            }, {
              title: '通过',
              value: 'ACCOUNT:MCH:MCHS:BASE/PASS',
              disabled: false,
            }, {
              title: '驳回',
              value: 'ACCOUNT:MCH:MCHS:BASE/REJECT',
              disabled: false,
            }, {
              title: '冻结',
              value: 'ACCOUNT:MCH:MCHS:BASE/FROZEN',
              disabled: false,
            }, {
              title: '关闭',
              value: 'ACCOUNT:MCH:MCHS:BASE/CLOSED',
              disabled: false,
            }, {
              title: '激活',
              value: 'ACCOUNT:MCH:MCHS:BASE/ACTIVATE',
              disabled: false,
            }]
          }, {
            title: '结算信息',
            value: 'ACCOUNT:MCH:MCHS:SETTLEMENT',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:MCH:MCHS:SETTLEMENT/SHOW',
              disabled: false,
            }, {
              title: '新增',
              value: 'ACCOUNT:MCH:MCHS:SETTLEMENT/ADD',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:MCH:MCHS:SETTLEMENT/EDIT',
              disabled: false,
            }, {
              title: '删除',
              value: 'ACCOUNT:MCH:MCHS:SETTLEMENT/DELETE',
              disabled: false,
            }]
          }, {
            title: '渠道信息',
            value: 'ACCOUNT:MCH:MCHS:CHANNEL',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:MCH:MCHS:CHANNEL/SHOW',
              disabled: false,
            }, {
              title: '新增',
              value: 'ACCOUNT:MCH:MCHS:CHANNEL/ADD',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:MCH:MCHS:CHANNEL/EDIT',
              disabled: false,
            }, {
              title: '删除',
              value: 'ACCOUNT:MCH:MCHS:CHANNEL/DELETE',
              disabled: false,
            }, {
              title: '配置',
              value: 'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE',
              disabled: false,
              child: [{
                title: '查看',
                value: 'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/SHOW',
                disabled: false,
              },{
                title: '新增',
                value: 'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/ADD',
                disabled: false,
              },{
                title: '删除',
                value: 'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/DELETE',
                disabled: false,
              },{
                title: '编辑',
                value: 'ACCOUNT:MCH:MCHS:CHANNEL/CONFIGURE/EDIT',
                disabled: false,
              }]
            }]
          }, {
            title: '产品信息',
            value: 'ACCOUNT:MCH:MCHS:PRODUCT',
            disabled: false,
            child: [{
                title: '查看',
                value: 'ACCOUNT:MCH:MCHS:PRODUCT/SHOW',
                disabled: false,
              },{
              title: '支付产品',
              value: 'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP',
              disabled: false,
              child: [{
                title: '状态',
                value: 'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/STATUS',
                disabled: false,
              },{
                title: 'IP制导',
                value: 'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/IPZD',
                disabled: false,
              },{
                title: '贸易模式',
                value: 'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/MYMS',
                disabled: false,
              },{
                title: '智能路由',
                value: 'ACCOUNT:MCH:MCHS:PRODUCT/ZFCP/INTTER',
                disabled: false,
              }]
            },{
              title: '代付产品',
              value: 'ACCOUNT:MCH:MCHS:PRODUCT/DFCP',
              disabled: false,
              child: [{
                title: '状态',
                value: 'ACCOUNT:MCH:MCHS:PRODUCT/DFCP/STATUS',
                disabled: false,
              }]
            },{
              title: '分账产品',
              value: 'ACCOUNT:MCH:MCHS:PRODUCT/FZCP',
              disabled: false,
              child: [{
                title: '状态',
                value: 'ACCOUNT:MCH:MCHS:PRODUCT/FZCP/STATUS',
                disabled: false,
              }]
            },]
          }, {
            title: '配置信息',
            value: 'ACCOUNT:MCH:MCHS:CONFIG',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:MCH:MCHS:CONFIG/SHOW',
              disabled: false,
            }, {
              title: '新增',
              value: 'ACCOUNT:MCH:MCHS:CONFIG/ADD',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:MCH:MCHS:CONFIG/EDIT',
              disabled: false,
            }, {
              title: '删除',
              value: 'ACCOUNT:MCH:MCHS:CONFIG/DELETE',
              disabled: false,
            }]
          }, {
            title: '终端信息',
            value: 'ACCOUNT:MCH:MCHS:PORT',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:MCH:MCHS:PORT/SHOW',
              disabled: false,
            }, {
              title: '新增',
              value: 'ACCOUNT:MCH:MCHS:PORT/ADD',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:MCH:MCHS:PORT/EDIT',
              disabled: false,
            }, {
              title: '删除',
              value: 'ACCOUNT:MCH:MCHS:PORT/DELETE',
              disabled: false,
            }, {
              title: '下载',
              value: 'ACCOUNT:MCH:MCHS:PORT/DOWNLOAD',
              disabled: false,
            }]
          }]
        }]
      }]
    }, {
      title: '代理商管理',
      value: 'ACCOUNT:AGENT',
      disabled: false,
      child: [{
        title: '代理商列表',
        value: 'ACCOUNT:AGENT:AGENTS',
        disabled: false,
        child: [{
          title: '查看',
          value: 'ACCOUNT:AGENT:AGENTS/SHOW',
          disabled: false,
        }, {
          title: '新增',
          value: 'ACCOUNT:AGENT:AGENTS/ADD',
          disabled: false,
        }, {
          title: '详情',
          value: 'ACCOUNT:AGENT:AGENTS/DETAIL',
          disabled: false,
          child: [{
            title: '基本信息',
            value: 'ACCOUNT:AGENT:AGENTS:BASE/JBXX',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JBXX/SHOW',
              disabled: false,
            },{
              title: '重发邮件',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JBXX/RESENDMAIl',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JBXX/EDIT',
              disabled: false,
            }, {
              title: '通过',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JBXX/PASS',
              disabled: false,
            }, {
              title: '驳回',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JBXX/REJECT',
              disabled: false,
            }, {
              title: '冻结',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JBXX/FROZEN',
              disabled: false,
            }, {
              title: '关闭',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JBXX/CLOSED',
              disabled: false,
            }, {
              title: '激活',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JBXX/ACTIVATE',
              disabled: false,
            }]
          },{
            title: '结算信息',
            value: 'ACCOUNT:AGENT:AGENTS:BASE/JSXX',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JSXX/SHOW',
              disabled: false,
            }, {
              title: '新增',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JSXX/ADD',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JSXX/EDIT',
              disabled: false,
            }, {
              title: '删除',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/JSXX/DELETE',
              disabled: false,
            }]
          },{
            title: '产品信息',
            value: 'ACCOUNT:AGENT:AGENTS:BASE/CPXX',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/CPXX/SHOW',
              disabled: false,
            }, {
              title: '新增',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/CPXX/ADD',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/CPXX/EDIT',
              disabled: false,
            }, {
              title: '删除',
              value: 'ACCOUNT:AGENT:AGENTS:BASE/CPXX/DELETE',
              disabled: false,
            }]
          }]
        }]
      }]
    }, {
      title: '分账商户管理',
      value: 'ACCOUNT:ALLOC',
      disabled: false,
      child: [{
        title: '分账商户列表',
        value: 'ACCOUNT:ALLOC:MERCHNATS',
        disabled: false,
        child: [{
          title: '查看',
          value: 'ACCOUNT:ALLOC:MERCHNATS/SHOW',
          disabled: false,
        }, {
          title: '新增',
          value: 'ACCOUNT:ALLOC:MERCHNATS/ADD',
          disabled: false,
        }, {
          title: '详情',
          value: 'ACCOUNT:ALLOC:MERCHNATS/DETAIL',
          disabled: false,
          child: [{
            title: '基本信息',
            value: 'ACCOUNT:ALLOC:MERCHNATS:BASE',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:ALLOC:MERCHNATS:BASE/SHOW',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:ALLOC:MERCHNATS:BASE/EDIT',
              disabled: false,
            }]
          }, {
            title: '分账商户信息',
            value: 'ACCOUNT:ALLOC:MERCHNATS:ACCOUNT',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ACCOUNT:ALLOC:MERCHNATS:ACCOUNT/SHOW',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ACCOUNT:ALLOC:MERCHNATS:ACCOUNT/EDIT',
              disabled: false,
            }]
          }]
        }]
      }]
    }, {
      title: '电子账簿商户管理',
      value: 'ELECTRLED:MCHCOM',
      disabled: false,
      child: [{
        title: '电子账簿商户列表',
        value: 'ELECTRLED:MCHCOM:MCHLIST',
        disabled: false,
        child: [{
          title: '查看',
          value: 'ELECTRLED:MCHCOM:MCHLIST/SHOW',
          disabled: false,
        }, {
          title: '新增',
          value: 'ELECTRLED:MCHCOM:MCHLIST/ADD',
          disabled: false,
        }, {
          title: '详情',
          value: 'ELECTRLED:MCHCOM:MCHLIST/DETAIL',
          disabled: false,
          child: [{
            title: '基本信息',
            value: 'ELECTRLED:MCHCOM:MCHLIST:BASE',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ELECTRLED:MCHCOM:MCHLIST:BASE/SHOW',
              disabled: false,
            },
            //   {
            //   title: '重发邮件',
            //   value: 'ELECTRLED:MCHCOM:MCHLIST:BASE/RESENDMAIl',
            //   disabled: false,
            // },
             {
              title: '编辑',
              value: 'ELECTRLED:MCHCOM:MCHLIST:BASE/EDIT',
              disabled: false,
            }, {
              title: '进件',
              value: 'ELECTRLED:MCHCOM:MCHLIST:BASE/APPLY',
              disabled: false,
            }]
          }, {
            title: '结算信息',
            value: 'ELECTRLED:MCHCOM:MCHLIST:SETTLE',
            disabled: false,
          },{
            title: '关联渠道',
            value: 'ELECTRLED:MCHCOM:MCHLIST:SETTLEMENT',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ELECTRLED:MCHCOM:MCHLIST:SETTLEMENT/SHOW',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ELECTRLED:MCHCOM:MCHLIST:SETTLEMENT/EDIT',
              disabled: false,
            },]
          }, {
            title: '电子账簿',
            value: 'ELECTRLED:MCHCOM:MCHLIST:CHANNEL',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ELECTRLED:MCHCOM:MCHLIST:CHANNEL/SHOW',
              disabled: false,
            },{
              title: '同步',
              value: 'ELECTRLED:MCHCOM:MCHLIST:CHANNEL/SYNC',
              disabled: false,
            },{
              title: '开户',
              value: 'ELECTRLED:MCHCOM:MCHLIST:CHANNEL/OPENAN',
              disabled: false,
            }]
          }, {
            title: '产品信息',
            value: 'ELECTRLED:MCHCOM:MCHLIST:PRODUCT',
            disabled: false,
            // child: [{
            //   title: '查看',
            //   value: 'ELECTRLED:MCHCOM:MCHLIST:PRODUCT/SHOW',
            //   disabled: false,
            // }, {
            //   title: '编辑',
            //   value: 'ELECTRLED:MCHCOM:MCHLIST:PRODUCT/EDIT',
            //   disabled: false,
            // }, {
            //   title: '新增',
            //   value: 'ELECTRLED:MCHCOM:MCHLIST:PRODUCT/ADD',
            //   disabled: false,
            // }]
          }, {
            title: '补充材料',
            value: 'ELECTRLED:MCHCOM:MCHLIST:REPLENISH',
            disabled: false,
            child: [{
              title: '查看',
              value: 'ELECTRLED:MCHCOM:MCHLIST:REPLENISH/SHOW',
              disabled: false,
            }, {
              title: '编辑',
              value: 'ELECTRLED:MCHCOM:MCHLIST:REPLENISH/EDIT',
              disabled: false,
            }]
          },]
        }]
      }]      
    }, {
      title: '店铺管理',
      value: 'ACCOUNT:STORE',
      disabled: false,
      child: [
        {
          title: '店铺列表',
          value: 'ACCOUNT:STORE:STORES',
          disabled: false,
          child: [{
            title: '查看',
            value: 'ACCOUNT:STORE:STORES/SHOW',
            disabled: false,
          },{
            title: '进件配置',
            value: 'ACCOUNT:STORE:STORES/JJPZBTN',
            disabled: false,
          },{
            title: '新增',
            value: 'ACCOUNT:STORE:STORES/ADD',
            disabled: false,
          },{
            title: '路由状态',
            value: 'ACCOUNT:STORE:STORES/ROUTINGS',
            disabled: false,
          },{
            title: '商户显示状态',
            value: 'ACCOUNT:STORE:STORES/DISPALY',
            disabled: false,
          }, {
            title: '详情',
            value: 'ACCOUNT:STORE:STORES/DETAIL',
            disabled: false,
            child: [{
              title: '基本信息',
              value: 'ACCOUNT:STORE:STORES:BASE',
              disabled: false,
              child: [{
                title: '查看',
                value: 'ACCOUNT:STORE:STORES:BASE/SHOW',
                disabled: false,
              }, {
                title: '新增',
                value: 'ACCOUNT:STORE:STORES:BASE/ADD',
                disabled: false,
              }, {
                title: '编辑',
                value: 'ACCOUNT:STORE:STORES:BASE/EDIT',
                disabled: false,
              }, {
                title: '配置',
                value: 'ACCOUNT:STORE:STORES:BASE/CONFIG',
                disabled: false,
              }, {
                title: '进件',
                value: 'ACCOUNT:STORE:STORES:BASE/APPLY',
                disabled: false,
              }]
            }, {
              title: '报备商户信息',
              value: 'ACCOUNT:STORE:STORES:TRADE',
              disabled: false,
              child: [{
                title: '查看',
                value: 'ACCOUNT:STORE:STORES:TRADE/SHOW',
                disabled: false,
              }, {
                title: '新增',
                value: 'ACCOUNT:STORE:STORES:TRADE/ADD',
                disabled: false,
              }, {
                title: '删除',
                value: 'ACCOUNT:STORE:STORES:TRADE/DELETE',
                disabled: false,
              },{
                title: '详情',
                value: 'ACCOUNT:STORE:STORES:TRADE/DETAIL',
                disabled: false,
              }, 
              {
                title: '同步',
                value: 'ACCOUNT:STORE:STORES:TRADE/SYNC',
                disabled: false,
              }, {
                title: '修改',
                value: 'ACCOUNT:STORE:STORES:TRADE/EDIT',
                disabled: false,
              }, {
                title: '特约配置',
                value: 'ACCOUNT:STORE:STORES:TRADE/WX',
                disabled: false,
              }, {
                title: '产品报备',
                value: 'ACCOUNT:STORE:STORES:TRADE/PRODUCT',
                disabled: false,
              }, {
                title: '备注',
                value: 'ACCOUNT:STORE:STORES:TRADE/REMARK',
                disabled: false,
              }, {
                title: '激活状态',
                value: 'ACCOUNT:STORE:STORES:TRADE/ENABLED',
                disabled: false,
              }]
            }]
          }]
        }, {
          title: '店铺查询',
          value: 'ACCOUNT:STORE:FIND',
          disabled: false,
          child: [{
              title: '查看',
              value: 'ACCOUNT:STORE:FIND/SHOW',
              disabled: false,
            }, 
            {
              title: '详情',
              value: 'ACCOUNT:STORE:FIND/DETAIL',
              disabled: false
            }]
        }, {
          title: '进件队列',
          value: 'ACCOUNT:STORE:JJDL',
          disabled: false
        },
      ]
    }, {
      title: '小程序管理',
      value: 'ACCOUNT:WXAPP',
      disabled: false,
      child: [{
        title: '第三方列表',
        value: 'ACCOUNT:WXAPP:THIRDS',
        disabled: false,
        child: [{
          title: '查看',
          value: 'ACCOUNT:WXAPP:THIRDS/SHOW',
          disabled: false,
        }, {
          title: '新增',
          value: 'ACCOUNT:WXAPP:THIRDS/ADD',
          disabled: false,
        }, {
          title: '详情',
          value: 'ACCOUNT:WXAPP:THIRDS/DETAIL',
          disabled: false,
          child: [{
            title: '查看',
            value: 'ACCOUNT:WXAPP:THIRDS/DETAIL/SHOW',
            disabled: false,
          },{
            title: '编辑',
            value: 'ACCOUNT:WXAPP:THIRDS/DETAIL/EDIT',
            disabled: false,
          }]
        },{
          title: '启用状态',
          value: 'ACCOUNT:WXAPP:THIRDS/ENABLESTATUS',
          disabled: false,
        }]
      }, {
        title: '小程序列表',
        value: 'ACCOUNT:WXAPP:APPS',
        disabled: false,
        child: [{
          title: '查看',
          value: 'ACCOUNT:WXAPP:APPS/SHOW',
          disabled: false,
        }, {
          title: '新增',
          value: 'ACCOUNT:WXAPP:APPS/ADD',
          disabled: false,
        }, {
          title: '详情',
          value: 'ACCOUNT:WXAPP:APPS/DETAIL',
          disabled: false,
        }]
      },{
        title: '终端小程序配置',
        value: 'ACCOUNT:TERMINAL:CiF',
        disabled: false,
        child: [{
          title: '查看',
          value: 'ACCOUNT:TERMINAL:CiF/SHOW',
          disabled: false,
        },{
          title: '编辑',
          value: 'ACCOUNT:TERMINAL:CiF/EDIT',
          disabled: false,
        }, {
          title: '终端小程序权限',
          value: 'ACCOUNT:TERMINAL:CiF/ZDXCXQX',
          disabled: false,
        }, {
          title: '退款免审模式',
          value: 'ACCOUNT:TERMINAL:CiF/TKMSMS',
          disabled: false,
        }, {
          title: '手工结算免审模式',
          value: 'ACCOUNT:TERMINAL:CiF/SGJSMSMS',
          disabled: false,
        }, {
          title: '修改账户权限',
          value: 'ACCOUNT:TERMINAL:CiF/XGZHQX',
          disabled: false,
        }]
      }]
    }, {
      title: '终端IP管理',
      value: 'ACCOUNT:IP',
      disabled: false,
      child: [{
        title: '终端IP配置',
        value: 'ACCOUNT:IP:SETTING',
        disabled: false,
        child: [{
          title: '查看',
          value: 'ACCOUNT:IP:SETTING/SHOW',
          disabled: false,
        }, {
          title: '新增',
          value: 'ACCOUNT:IP:SETTING/ADD',
          disabled: false,
        }, {
          title: '修改',
          value: 'ACCOUNT:IP:SETTING/EDIT',
          disabled: false,
        }, {
          title: '删除',
          value: 'ACCOUNT:IP:SETTING/DELETE',
          disabled: false,
        }]
      }]
    }, {
      title: '智能路由管理', 
      value: 'ACCOUNT:INTETT',
      disabled: false,
      child: [{
        title: '智能路由配置', 
        value: 'ACCOUNT:INTETT:DEPLOY',
        disabled: false,
        child: [{
          title: '查看',
          value: 'ACCOUNT:INTETT:DEPLOY/SHOW',
          disabled: false,
        }, {
          title: '新增',
          value: 'ACCOUNT:INTETT:DEPLOY/ADD',
          disabled: false,
        }, {
          title: '重置状态', 
          value: 'ACCOUNT:INTETT:DEPLOY/RESET',
          disabled: false,
        }, {
          title: '冻结/解冻', 
          value: 'ACCOUNT:INTETT:DEPLOY/FREEZE',
          disabled: false,
        }]
      }]
    }]
},
{
  title: '交易中心',
  value: 'TRADE',
  disabled: false,
  child: [{
    title: '收款管理',
    value: 'TRADE:RECEIPT',
    disabled: false,
    child: [{
      title: '交易查询',
      value: 'TRADE:RECEIPT:ORDERS',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:RECEIPT:ORDERS/SHOW',
        disabled: false,
      },{
        title: '导出',
        value: 'TRADE:RECEIPT:ORDERS/EXPORT',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:RECEIPT:ORDERS/DETAIL',
        disabled: false,
      }, {
        title: '退款',
        value: 'TRADE:RECEIPT:ORDERS/REFUND',
        disabled: false,
      }, {
        title: '同步',
        value: 'TRADE:RECEIPT:ORDERS/SYNC',
        disabled: false,
      }, {
        title: '通知',
        value: 'TRADE:RECEIPT:ORDERS/NOTIFY',
        disabled: false,
      }]
    }, {
      title: '退款查询',
      value: 'TRADE:RECEIPT:REFUNDS',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:RECEIPT:REFUNDS/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'TRADE:RECEIPT:REFUNDS/EXPORT',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:RECEIPT:REFUNDS/DETAIL',
        disabled: false,
      }, {
        title: '同步',
        value: 'TRADE:RECEIPT:REFUNDS/SYNC',
        disabled: false,
      }, {
        title: '通知',
        value: 'TRADE:RECEIPT:REFUNDS/NOTIFY',
        disabled: false,
      }]
    }, {
      title: '退款审核',
      value: 'TRADE:RECEIPT:REFUND_CHECK',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:RECEIPT:REFUND_CHECK/SHOW',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:RECEIPT:REFUND_CHECK/DETAIL',
        disabled: false,
      }, {
        title: '通过',
        value: 'TRADE:RECEIPT:REFUND_CHECK/PASS',
        disabled: false,
      }, {
        title: '驳回',
        value: 'TRADE:RECEIPT:REFUND_CHECK/REJECT',
        disabled: false,
      }]
    }]
  },
  {
    title: '付款管理',
    value: 'TRADE:PAY',
    disabled: false,
    child: [{
      title: '账号列表',
      value: 'TRADE:PAY:ACCOUNTS',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:PAY:ACCOUNTS/SHOW',
        disabled: false,
      },{
        title: '账户状态',
        value: 'TRADE:PAY:ACCOUNTS/ZHSTATUS',
        disabled: false,
      },]
    }, {
      title: '代付查询',
      value: 'TRADE:PAY:ORDERS',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:PAY:ORDERS/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'TRADE:PAY:ORDERS/EXPORT',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:PAY:ORDERS/DETAIL',
        disabled: false,
      }, {
        title: '同步',
        value: 'TRADE:PAY:ORDERS/SYNC',
        disabled: false,
      }, {
        title: '通知',
        value: 'TRADE:PAY:ORDERS/NOTIFY',
        disabled: false,
      }]
    }]
  },
  {
    title: '分账管理',
    value: 'TRADE:ALLOC',
    disabled: false,
    child: [{
      title: '账号管理',
      value: 'TRADE:ALLOC:ACCOUNTS',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:ALLOC:ACCOUNTS/SHOW',
        disabled: false,
      },{
        title: '账户状态',
        value: 'TRADE:ALLOC:ACCOUNTS/ZHSTATUS',
        disabled: false,
      },]
    }, {
      title: '分账交易查询',
      value: 'TRADE:ALLOC:ORDERS',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:ALLOC:ORDERS/SHOW',
        disabled: false,
      },{
        title: '导出',
        value: 'TRADE:ALLOC:ORDERS/EXPORT',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:ALLOC:ORDERS/DETAIL',
        disabled: false,
      },{
        title: '同步',
        value: 'TRADE:ALLOC:ORDERS/SYNC',
        disabled: false,
      },{
        title: '通知',
        value: 'TRADE:ALLOC:ORDERS/NOTIFY',
        disabled: false,
      }]
    }, {
      title: '分账退款查询',
      value: 'TRADE:ALLOC:REFUNDS',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:ALLOC:REFUNDS/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'TRADE:ALLOC:REFUNDS/EXPORT',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:ALLOC:REFUNDS/DETAIL',
        disabled: false,
      }]
    }, {
      title: '分账代付查询',
      value: 'TRADE:ALLOC:REFUNDSPAY',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:ALLOC:REFUNDSPAY/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'TRADE:ALLOC:REFUNDSPAY/EXPORT',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:ALLOC:REFUNDSPAY/DETAIL',
        disabled: false,
      }, {
        title: '同步',
        value: 'TRADE:ALLOC:REFUNDSPAY/SYNC',
        disabled: false,
      }, {
        title: '通知',
        value: 'TRADE:ALLOC:REFUNDSPAY/NOTICE',
        disabled: false,
      }]
    }, {
      title: '手工结算查询',
      value: 'TRADE:ALLOC:MANUAL_SETTLES',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:ALLOC:MANUAL_SETTLES/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'TRADE:ALLOC:MANUAL_SETTLES/EXPORT',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:ALLOC:MANUAL_SETTLES/DETAIL',
        disabled: false,
      }, {
        title: '同步',
        value: 'TRADE:ALLOC:MANUAL_SETTLES/SYNC',
        disabled: false,
      }, {
        title: '通知',
        value: 'TRADE:ALLOC:MANUAL_SETTLES/NOTIFY',
        disabled: false,
      }]
    }, {
      title: '手工结算审核',
      value: 'TRADE:ALLOC:MANUAL_SGJSSH',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:ALLOC:MANUAL_SGJSSH/SHOW',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:ALLOC:MANUAL_SGJSSH/DETAIL',
        disabled: false,
      }, {
        title: '通过',
        value: 'TRADE:ALLOC:MANUAL_SGJSSH/PASS',
        disabled: false,
      }, {
        title: '驳回',
        value: 'TRADE:ALLOC:MANUAL_SGJSSH/REJECT',
        disabled: false,
      }]
    }, {
      title: '自动结算查询',
      value: 'TRADE:ALLOC:AUTO_SETTLES',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:ALLOC:AUTO_SETTLES/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'TRADE:ALLOC:AUTO_SETTLES/EXPORT',
        disabled: false,
      }]
    }, {
      title: '担保查询',
      value: 'TRADE:ALLOC:GUARANTEE_ORDERS',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:ALLOC:GUARANTEE_ORDERS/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'TRADE:ALLOC:GUARANTEE_ORDERS/EXPORT',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:ALLOC:GUARANTEE_ORDERS/DETAIL',
        disabled: false,
      }]
    }, {
      title: '账户转账查询',
      value: 'TRADE:ALLOC:TRANSFER_ORDERS',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:ALLOC:TRANSFER_ORDERS/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'TRADE:ALLOC:TRANSFER_ORDERS/EXPORT',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:ALLOC:TRANSFER_ORDERS/DETAIL',
        disabled: false,
      }, {
        title: '明细',
        value: 'TRADE:ALLOC:TRANSFER_ORDERS/MINUTIA',
        disabled: false,
      }, {
        title: '同步',
        value: 'TRADE:ALLOC:TRANSFER_ORDERS/SYNC',
        disabled: false,
      }, {
        title: '通知',
        value: 'TRADE:ALLOC:TRANSFER_ORDERS/NOTICE',
        disabled: false,
      }]
    }]
  },{
		title: '电子账簿',
		value: 'ELECTRON:BOOK',
		disabled: false,
		child: [{
			title: '电子账簿列表',
			value: 'ELECTRON:BOOK:INFO',
			disabled: false,
      child: [{
        title: '查看',
        value: 'ELECTRON:BOOK:INFO/SHOW',
        disabled: false,
      },{
        title: '状态',
        value: 'ELECTRON:BOOK:INFO/STATUS',
        disabled: false,
      },]
		},{
			title: '入金查询',
			value: 'ELECTRON:BOOK:DEPOSIT',
			disabled: false,
      child: [{
        title: '查看',
        value: 'ELECTRON:BOOK:DEPOSIT/SHOW',
        disabled: false,
      },{
        title: '导出',
        value: 'ELECTRON:BOOK:DEPOSIT/EXPORT',
        disabled: false,
      },]
		},{
			title: '出金查询',
			value: 'ELECTRON:BOOK:GILDWD',
			disabled: false,
      child: [{
        title: '查看',
        value: 'TELECTRON:BOOK:GILDWD/SHOW',
        disabled: false,
      },{
        title: '导出',
        value: 'ELECTRON:BOOK:GILDWD/EXPORT',
        disabled: false,
      },{
        title: '同步',
        value: 'ELECTRON:BOOK:GILDWD/SYNC',
        disabled: false,
      }]
		}]
	}, {
    title: '跨境',
    value: 'TRADE:CUSTOMS',
    disabled: false,
    child: [{
      title: '海关推单查询',
      value: 'TRADE:CUSTOMS:ORDERS',
      disabled: false,
      child: [{
        title: '查看',
        value: 'TRADE:CUSTOMS:ORDERS/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'TRADE:CUSTOMS:ORDERS/EXPORT',
        disabled: false,
      }, {
        title: '详情',
        value: 'TRADE:CUSTOMS:ORDERS/DETAIL',
        disabled: false,
      }, {
        title: '同步',
        value: 'TRADE:CUSTOMS:ORDERS/SYNC',
        disabled: false,
      }]
    }]
  }]
},
{
  title: '数据中心',
  value: 'DATA',
  disabled: false,
  child: [{
    title: '平台概况',
    value: 'DATA:PLATFORM',
    disabled: false,
    child:[{
      title: '交易概况',
      value: 'DATA:PLATFORM:TRADE_SURVEY',
      disabled: false,
    }, {
      title: '收益概况',
      value: 'DATA:PLATFORM:PROFIT_SURVEY',
      disabled: false,
    }]
  }, {
    title: '数据分析',
    value: 'DATA:ANALYSIS',
    disabled: false,
    child: [{
      title: '商户统计',
      value: 'DATA:ANALYSIS:MERCHANT',
      disabled: false,
    }, {
      title: '店铺统计',
      value: 'DATA:ANALYSIS:STORE',
      disabled: false,
    }, {
      title: '时段分析',
      value: 'DATA:ANALYSIS:TIMES',
      disabled: false,
    }, {
      title: '终端IP分析',
      value: 'DATA:ANALYSIS:IP',
      disabled: false,
      child:[{
        title: '查看',
        value: 'DATA:ANALYSIS:IP/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'DATA:ANALYSIS:IP/EXPORT',
        disabled: false,
      },]
    }]
  }, {
    title: '汇总明细',
    value: 'DATA:SUMMARY',
    disabled: false,
    child: [{
      title: '机构汇总',
      value: 'DATA:SUMMARY:ORGANIZATION',
      disabled: false,
      child:[{
        title: '查看',
        value: 'DATA:SUMMARY:ORGANIZATION/SHOW',
        disabled: false,
      }, {
        title: '详情',
        value: 'DATA:SUMMARY:ORGANIZATION/DETAIL',
        disabled: false,
      }]
    }, {
      title: '渠道汇总',
      value: 'DATA:SUMMARY:CHANNEL',
      disabled: false,
      child:[{
        title: '查看',
        value: 'DATA:SUMMARY:CHANNEL/SHOW',
        disabled: false,
      }, {
        title: '详情',
        value: 'DATA:SUMMARY:CHANNEL/DETAIL',
        disabled: false,
      }]
    }, {
      title: '商户汇总',
      value: 'DATA:SUMMARY:MERCHANT',
      disabled: false,
      child:[{
        title: '查看',
        value: 'DATA:SUMMARY:MERCHANT/SHOW',
        disabled: false,
      }, {
        title: '详情',
        value: 'DATA:SUMMARY:MERCHANT/DETAIL',
        disabled: false,
      }]
    }, {
      title: '代理商汇总',
      value: 'DATA:SUMMARY:AGENT',
      disabled: false,
      child:[{
        title: '查看',
        value: 'DATA:SUMMARY:AGENT/SHOW',
        disabled: false,
      }, {
        title: '详情',
        value: 'DATA:SUMMARY:AGENT/DETAIL',
        disabled: false,
      }]
    }]
    // }, {
    //   title: '对账管理',
    //   value: 'DATA:BILL',
    //   disabled: false,
    //   child: [{
    //     title: '对账任务',
    //     value: 'DATA:BILL:TASKS',
    //     disabled: false,
    //   }, {
    //     title: '商户对账',
    //     value: 'DATA:BILL:MERCHANT',
    //     disabled: false,
    //   }, {
    //     title: '对账概要',
    //     value: 'DATA:BILL:SURVEY',
    //     disabled: false,
    //   }, {
    //     title: '对账异常',
    //     value: 'DATA:BILL:EXCEPTION',
    //     disabled: false,
    //   }]
  }, {
    title: '账单管理',
    value: 'DATA:BILLS',
    disabled: false,
    child: [{
      title: '账单查询',
      value: 'DATA:BILLS:ORDERS',
      disabled: false,
      child:[{
        title: '查看',
        value: 'DATA:BILLS:ORDERS/SHOW',
        disabled: false,
      }, {
        title: '下载',
        value: 'DATA:BILLS:ORDERS/DOWNLOAD',
        disabled: false,
      }, {
        title: '重新执行',
        value: 'DATA:BILLS:ORDERS/REPLAY',
        disabled: false,
      }]
    }, {
      title: '资金汇总',
      value: 'DATA:BILLS:PRICE',
      disabled: false,
      child:[{
        title: '查看',
        value: 'DATA:BILLS:PRICE/SHOW',
        disabled: false,
      }, {
        title: '导出',
        value: 'DATA:BILLS:PRICE/EXPORT',
        disabled: false,
      }]
    }]
  }]
}, {
  title: '运维中心',
  value: 'OPERATION',
  disabled: false,
  child: [

    {
      title: '订单管理',
      value: 'OPERATION:ORDERS',
      disabled: false,
      child: [{
        title: '交易订单管理',
        value: 'OPERATION:ORDERS:TRADE',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:ORDERS:ORDER/SHOW',
          disabled: false,
        },{
          title: '详情',
          value: 'OPERATION:ORDERS:ORDER/DETAIL',
          disabled: false,
        }, {
          title: '导入',
          value: 'OPERATION:ORDERS:ORDER/IMPORT',
          disabled: false,
        }, {
          title: '同步',
          value: 'OPERATION:ORDERS:ORDER/SYNC',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:ORDERS:ORDER/NOTIFY',
          disabled: false,
        }]
      }, {
        title: '退款订单管理',
        value: 'OPERATION:ORDERS:REFUND',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:ORDERS:REFUND/SHOW',
          disabled: false,
        },{
          title: '详情',
          value: 'OPERATION:ORDERS:REFUND/DETAIL',
          disabled: false,
        }, {
          title: '导入',
          value: 'OPERATION:ORDERS:REFUND/IMPORT',
          disabled: false,
        }, {
          title: '同步',
          value: 'OPERATION:ORDERS:REFUND/SYNC',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:ORDERS:REFUND/NOTIFY',
          disabled: false,
        }]
      }, {
        title: '代付订单管理',
        value: 'OPERATION:ORDERS:PAY',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:ORDERS:PAY/SHOW',
          disabled: false,
        },{
          title: '详情',
          value: 'OPERATION:ORDERS:PAY/DETAIL',
          disabled: false,
        }, {
          title: '导入',
          value: 'OPERATION:ORDERS:PAY/IMPORT',
          disabled: false,
        }, {
          title: '同步',
          value: 'OPERATION:ORDERS:PAY/SYNC',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:ORDERS:PAY/NOTIFY',
          disabled: false,
        }]
      }, {
        title: '分账交易订单管理',
        value: 'OPERATION:ORDERS:ALLOC',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:ORDERS:ALLOC/SHOW',
          disabled: false,
        },{
          title: '详情',
          value: 'OPERATION:ORDERS:ALLOC/DETAIL',
          disabled: false,
        }, {
          title: '导入',
          value: 'OPERATION:ORDERS:ALLOC/IMPORT',
          disabled: false,
        }, {
          title: '同步',
          value: 'OPERATION:ORDERS:ALLOC/SYNC',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:ORDERS:ALLOC/NOTIFY',
          disabled: false,
        }]
      }, {
        title: '分账代付订单管理',
        value: 'OPERATION:ORDERS:ALLOCPAY',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:ORDERS:ALLOCPAY/SHOW',
          disabled: false,
        },{
          title: '详情',
          value: 'OPERATION:ORDERS:ALLOCPAY/DETAIL',
          disabled: false,
        }, {
          title: '导入',
          value: 'OPERATION:ORDERS:ALLOCPAY/IMPORT',
          disabled: false,
        }, {
          title: '同步',
          value: 'OPERATION:ORDERS:ALLOCPAY/SYNC',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:ORDERS:ALLOCPAY/NOTIFY',
          disabled: false,
        }]
      }, {
        title: '分账结算订单管理',
        value: 'OPERATION:ORDERS:REFUNDJS',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:ORDERS:REFUNDJS/SHOW',
          disabled: false,
        },{
          title: '详情',
          value: 'OPERATION:ORDERS:REFUNDJS/DETAIL',
          disabled: false,
        }, {
          title: '导入',
          value: 'OPERATION:ORDERS:REFUNDJS/IMPORT',
          disabled: false,
        }, {
          title: '同步',
          value: 'OPERATION:ORDERS:REFUNDJS/SYNC',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:ORDERS:REFUNDJS/NOTIFY',
          disabled: false,
        }]
      }, {
        title: '分账转账订单管理',
        value: 'OPERATION:ORDERS:TRANSFERZZ',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:ORDERS:TRANSFERZZ/SHOW',
          disabled: false,
        },{
          title: '详情',
          value: 'OPERATION:ORDERS:TRANSFERZZ/DETAIL',
          disabled: false,
        }, {
          title: '明细',
          value: 'OPERATION:ORDERS:TRANSFERZZ/MINUTIA',
          disabled: false,
        }, {
          title: '导入',
          value: 'OPERATION:ORDERS:TRANSFERZZ/IMPORT',
          disabled: false,
        }, {
          title: '同步',
          value: 'OPERATION:ORDERS:TRANSFERZZ/SYNC',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:ORDERS:TRANSFERZZ/NOTIFY',
          disabled: false,
        }]
      }]
    },



    {
      title: '通知管理',
      value: 'OPERATION:NOTICES',
      disabled: false,
      child: [{
        title: '交易通知查询',
        value: 'OPERATION:NOTICES:ORDER',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:NOTICES:ORDER/SHOW',
          disabled: false,
        }, {
          title: '详情',
          value: 'OPERATION:NOTICES:ORDER/DETAIL',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:NOTICES:ORDER/SYNC',
          disabled: false,
        }]
      }, {
        title: '退款通知查询',
        value: 'OPERATION:NOTICE:REFUND',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:NOTICES:REFUND/SHOW',
          disabled: false,
        }, {
          title: '详情',
          value: 'OPERATION:NOTICES:REFUND/DETAIL',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:NOTICES:REFUND/SYNC',
          disabled: false,
        }]
      }, {
        title: '付款通知查询',
        value: 'OPERATION:NOTICES:PAY',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:NOTICES:PAY/SHOW',
          disabled: false,
        }, {
          title: '详情',
          value: 'OPERATION:NOTICES:PAY/DETAIL',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:NOTICES:PAY/SYNC',
          disabled: false,
        }]
      }, {
        title: '分账交易通知查询',
        value: 'OPERATION:NOTICES:ALLOC',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:NOTICES:ALLOC/SHOW',
          disabled: false,
        }, {
          title: '详情',
          value: 'OPERATION:NOTICES:ALLOC/DETAIL',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:NOTICES:ALLOC/SYNC',
          disabled: false,
        }]
      }, {
        title: '分账代付通知查询',
        value: 'OPERATION:NOTICES:ALLOCPAY',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:NOTICES:ALLOCPAY/SHOW',
          disabled: false,
        }, {
          title: '详情',
          value: 'OPERATION:NOTICES:ALLOCPAY/DETAIL',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:NOTICES:ALLOCPAY/SYNC',
          disabled: false,
        }]
      }, {
        title: '分账结算通知查询',
        value: 'OPERATION:NOTICES:REFUNDJS',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:NOTICES:REFUNDJS/SHOW',
          disabled: false,
        }, {
          title: '详情',
          value: 'OPERATION:NOTICES:REFUNDJS/DETAIL',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:NOTICES:REFUNDJS/SYNC',
          disabled: false,
        }]
      }, {
        title: '分账担保通知查询',
        value: 'OPERATION:NOTICES:GUARANTEE',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:NOTICES:GUARANTEE/SHOW',
          disabled: false,
        }, {
          title: '详情',
          value: 'OPERATION:NOTICES:GUARANTEE/DETAIL',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:NOTICES:GUARANTEE/SYNC',
          disabled: false,
        }]
      }, {
        title: '分账转账通知查询',
        value: 'OPERATION:NOTICES:TRANSFERZZ',
        disabled: false,
        child: [{
          title: '查看',
          value: 'OPERATION:NOTICES:TRANSFERZZ/SHOW',
          disabled: false,
        }, {
          title: '详情',
          value: 'OPERATION:NOTICES:TRANSFERZZ/DETAIL',
          disabled: false,
        }, {
          title: '明细',
          value: 'OPERATION:NOTICES:TRANSFERZZ/MINUTIA',
          disabled: false,
        }, {
          title: '通知',
          value: 'OPERATION:NOTICES:TRANSFERZZ/SYNC',
          disabled: false,
        }]
      }]
    },
    {
      title: '测试管理',
      value: 'OPERATION:RECEIPT_TEST',
      disabled: false,
      child: [{
        title: '收款测试',
        value: 'SYSTEM:RECEIPT_TEST:PAY',
        disabled: false,
      }]
    }
  ]
}, {
  title: '系统管理',
  value: 'SYSTEM',
  disabled: false,
  child: [{
    title: '系统管理',
    value: 'SYSTEM:SYSTEM',
    disabled: false,
    child: [{
      title: '角色管理',
      value: 'SYSTEM:SYSTEM:ROLES',
      disabled: false,
      child: [{
        title: '查看',
        value: 'SYSTEM:SYSTEM:ROLES/SHOW',
        disabled: false,
      }, {
        title: '新增',
        value: 'SYSTEM:SYSTEM:ROLES/ADD',
        disabled: false,
      }, {
        title: '编辑',
        value: 'SYSTEM:SYSTEM:ROLES/EDIT',
        disabled: false,
      }, {
        title: '删除',
        value: 'SYSTEM:SYSTEM:ROLES/DELETE',
        disabled: false,
      }]
    }, {
      title: '员工管理',
      value: 'SYSTEM:SYSTEM:EMPLOYEES',
      disabled: false,
      child: [{
        title: '查看',
        value: 'SYSTEM:SYSTEM:EMPLOYEES/SHOW',
        disabled: false,
      }, {
        title: '新增',
        value: 'SYSTEM:SYSTEM:EMPLOYEES/ADD',
        disabled: false,
      }, {
        title: '编辑',
        value: 'SYSTEM:SYSTEM:EMPLOYEES/EDIT',
        disabled: false,
      }, {
        title: '删除',
        value: 'SYSTEM:SYSTEM:EMPLOYEES/DELETE',
        disabled: false,
      }, {
        title: '状态',
        value: 'SYSTEM:SYSTEM:EMPLOYEES/STATUS',
        disabled: false,
      }]
    }, {
      title: '登录日志',
      value: 'SYSTEM:SYSTEM:LOGIN_LOGS',
      disabled: false,
    }, {
      title: '用户隐私二次认证',
      value: 'SYSTEM:SYSTEM:SECONDARYAUTH',
      disabled: false,
    }, {
      title: '其他配置',
      value: 'SYSTEM:GLOBAL_CONFIG',
      disabled: false,
      child: [{
        title: '查看',
        value: 'SYSTEM:GLOBAL_CONFIG/SHOW',
        disabled: false,
      }, {
        title: '编辑',
        value: 'SYSTEM:GLOBAL_CONFIG/EDIT',
        disabled: false,
      }
      // , {
      //   title: '激活',
      //   value: 'SYSTEM:GLOBAL_CONFIG/ACTIVATE',
      //   disabled: false,
      // }
      ]
    }]
  }]
}]
