<template>
  <div id="mian">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>角色名称</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="角色名称"
          ></el-input>
        </div>
        <div class="itembtn">
          <el-button class="searchBt" @click="getLists">查询</el-button>
          <el-button class="outputBt" @click="addShow" v-auth="'SYSTEM:SYSTEM:ROLES/ADD'">新增角色</el-button>
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>创建时间</p>
          </td>
          <td>
            <p>角色名称</p>
          </td>
          <td>
            <p>角色概述</p>
          </td>
          <td>
            <p>权限明细</p>
          </td>
          <td>
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td>
            <p>{{ v.createTime }}</p>
          </td>
          <td>
            <p>{{ v.roleName }}</p>
          </td>
          <td>
            <p>{{ v.roleDesc }}</p>
          </td>
          <td>
            <p><i class="lianjie" @click="getDetail(v, true)">查看</i></p>
          </td>
          <td>
            <p>
              <i v-auth="'SYSTEM:SYSTEM:ROLES/EDIT'"
                class="lianjie"
                @click="getDetail(v)"
                >编辑</i
              >
              <i class="lianjie" @click="delRole(v)"  v-auth="'SYSTEM:SYSTEM:ROLES/DELETE'">删除</i>
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-dialog
      :title="
        editShow === 0 ? '新增角色' : editShow === 1 ? '修改角色' : '查看角色'
      "
      :visible.sync="addUserShow"
      width="490px"
    >
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title"><i>*</i>角色名称</div>
          <el-input
            :disabled="roleDisable"
            v-model="formData.roleName"
            maxlength="128"
            placeholder="输入角色名称"
            tabindex="1"
          ></el-input>
          <div class="overAccount_ul_title"><i>*</i>权限明细</div>
          <!-- 权限列表 -->
          <el-tree
            :data="roleLists"
            :default-checked-keys="defaultRole"
            :props="roleProps"
            node-key="value"
            show-checkbox
            @check="roleChange"
            accordion
          >
          </el-tree>
          <!-- <el-checkbox-group :disabled="roleDisable" v-model="formData.resources" @change="onchange">
						<div class="role clear" v-for="(v) in roleLists" :key="v.title">
							<div class="h1">
								<el-checkbox :label="v.title"></el-checkbox>
							</div>
							<div style="float: left;">
								<div class="clear" v-for="(val) in v.child" :key="val.title">
									<div class="h2">
										<el-checkbox :label="val.title"></el-checkbox>
									</div>
									<div style="float: left;">
										<div v-for="(value) in val.child" :key="value.title">
											<div>
												<el-checkbox :label="value.title"></el-checkbox>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-checkbox-group> -->
        </li>
        <li class="ul-right">
          <div class="overAccount_ul_title">角色概述</div>
          <el-input
            :disabled="roleDisable"
            v-model="formData.roleDesc"
            maxlength="128"
            placeholder="输入角色概述"
            tabindex="2"
          ></el-input>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="addUserShow = false">取 消</el-button>
        <el-button class="addBt" @click="onSubmit" :disabled="buttonFlag"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getRoles,
  getRolesDetail,
  setRoles,
  delRoles,
} from "@/api/management/index.js";
import { roles } from "@/utils/roles.js";
export default {
  data() {
    return {
      tabData: [],
      addUserShow: false,
      roleLists: roles,
      defaultRole: [],
      formData: {
        roleId: 0,
        roleName: "",
        roleDesc: "",
        resources: [],
      },
      query: {
        keyword: "",
      },
      roleDisable: false,
      roleProps: {
        label: "title",
        children: "child",
      },
      editShow: 0, // 0 新增 1 修改 2 查看
      buttonFlag: false, //防止重复点击
    };
  },
  mounted() {
    this.getLists();
    this.$enter(this.$route.path, this.getLists);
  },
  methods: {
    getLists() {
      this.query.keyword = this.query.keyword.trim();
      getRoles(this.query).then((res) => {
        if (res.resultStatus) {
          this.tabData = res.resultData;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
        }
      });
    },
    // 修改、查看
    getDetail(value, bool = false) {
      getRolesDetail(value.roleId).then((res) => {
        if (res.resultStatus) {
          this.roleDisable = bool ? true : false;
          this.formData = res.resultData;
          this.defaultRole = this.roleFilter(
            this.formData.resources.split(";"),
            this.roleLists
          );
          if (bool) {
            this.roleLists = JSON.parse(
              JSON.stringify(this.roleLists).replace(/false/g, true)
            );
          } else {
            this.roleLists = JSON.parse(
              JSON.stringify(this.roleLists).replace(/true/g, false)
            );
          }
          this.editShow = bool ? 2 : 1;
          this.addUserShow = true;
        }
      });
    },
    // 新增确认
    addShow() {
      this.formData = {
        roleId: 0,
        roleName: "",
        roleDesc: "",
        resources: [],
      };
      this.editShow = 0;
      this.roleDisable = false;
      this.roleLists = JSON.parse(
        JSON.stringify(this.roleLists).replace(/true/g, false)
      );
      this.defaultRole = [];
      this.addUserShow = true;
    },
    // 提交
    onSubmit() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (!data.roleName) {
        this.$message.error("角色名称必填");
        return;
      }
      if (data.resources.length < 1) {
        this.$message.error("角色权限必选");
        return;
      }
      this.buttonFlag = true;
      setRoles(data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("保存成功");
          this.getLists();
          this.addUserShow = false;
          setTimeout(() => {
            this.buttonFlag = false;
          }, 2000);
        }
      });
    },
    //删除角色
    delRole(value) {
      this.$confirm("此操作将删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delRoles(value.roleId).then((res) => {
            if (res.resultStatus) {
              this.getLists();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 权限改变
    roleChange(e, list) {
      let roleList = list.checkedKeys.concat(list.halfCheckedKeys).join(";");
      this.formData.resources = roleList;
    },
    // 权限筛选
    roleFilter(roleList, roleLists) {
      let arr = [];
      roleLists.forEach((v) => {
        let child = roleList.find((res) => res === v.value) ? v.child : false;
        if (child) {
          arr = arr.concat(this.roleFilter(roleList, child));
          let reData = this.roleFilter(roleList, child);
          let reNum = child.filter((res) => reData.indexOf(res.value) !== -1);
          if (reNum.length !== child.length) {
            return arr;
          }
        }
        arr.push(roleList.find((res) => res === v.value));
        arr = arr.filter((res) => res !== undefined);
      });
      return arr;
    },
  },
};
</script>
<style scoped>
.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}

.role {
  width: 490px;
  margin-left: 13px;
}

.role .el-checkbox {
  color: #999999;
  margin-right: 0;
  margin-bottom: 12px;
}

.role .h1 {
  float: left;
  width: 159px;
}

.role .h2 {
  float: left;
  width: 174px;
}

.role .h3 {
  float: left;
}

.el-tree {
  width: 439px;
}
</style>
